/* styles.css */
/* styles.css */
.background-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: linear-gradient(to right, #ff3366, #ff9900);
  background: rgb(51, 43, 112);
  background: radial-gradient(circle, rgba(51, 43, 112, 1) 0%, rgba(20, 26, 46, 1) 79%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* Set text color to contrast with the background */
}

.gradient-background {
    width: 100%;
    height: 100%;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* Set the height of your gradient background */
    /* padding-left: 17%; */
}

/* Hide the text layer to prevent text from being displayed on top of PDF */
.pdf-container .rpv-core__text-layer {
  display: none;
}

/* Customize styles for the PDF viewer */
.pdf-container {
  width: 100%;
  height: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-container .rpv-core__viewer {
  width: 100%;
  height: 100%;
  max-width: none;
  /* background-color: #f2f2f2; */
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.pdf-container .rpv-core__page {
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.pdf-container .rpv-core__annotation__link {
  color: #007bff;
  text-decoration: underline;
}

.pdf-container .rpv-core__annotation__link:hover {
  color: #0056b3;
}

/* Responsive styles for smaller screens (phones) */
@media (max-width: 768px) {
  .pdf-container .rpv-core__viewer {
    border-radius: 0;
    box-shadow: none;
  }

  .pdf-container .rpv-core__page {
    margin: 5px;
  }
}
/* .white-text {
  color: white;
  text-align: center;
  padding-left: 0%; */
  /* Set text alignment to center */
  /* Add more styles as needed */
/* } */